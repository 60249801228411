import React, { useState } from 'react';
import { useQuery} from '@apollo/react-hooks';
import { useMutation } from '@apollo/client';
import { REQUEST_ANOTHER_VENDOR } from '@/graphql/mutations/requestAnotherVendor';
import { get, getOr} from 'lodash/fp';
import { ICustomer } from '@/types/customer';
import { showToast, toast } from '@/containers/StyledToastContainer/toast';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Button } from '@/components/designSystem/buttons';
import { SectionContainer } from '../../SectionContainer';
import { ReferencesList } from './ReferencesList';
import { AddVendor } from './AddVendor';
import { StyledTabPanelContainer, StyledButtonsContainer } from '../../styled';
import { ReactComponent as EmailIcon } from '@/assets/email.svg';
import { StyledAddVendorsForm } from './styled';
import { Caption } from '@/components/designSystem/Typography';
import { FlexColumn } from '@/components/designSystem/Layout';
import { GET_REMINDER_DETAILS } from '@/graphql/queries/getReferenceEmailActivity';
import { IStakeholder } from '@/types/stakeholder';
import {
  formatDateToDateTimeString,
} from '@/utils/date';

interface IProps {
  customerData?: ICustomer,
  refetchCustomerData: () => void,
  canSeeEditControl: boolean,
}

export const References = ({ customerData, refetchCustomerData, canSeeEditControl }: IProps) => {
  const isManualCustomer = get('isManualCustomer', customerData);

  const providerCompanyId = get('providerCompany.id', customerData);
  const stakeholders: IStakeholder[] = getOr([], 'seekerCompany.stakeholders', customerData);
  const primaryStakeholder = stakeholders.filter(stakeholder => stakeholder.isPrimaryContact == true)

  const { data } = useQuery(GET_REMINDER_DETAILS, { variables: { email: primaryStakeholder[0].email, providerCompanyId: providerCompanyId}});
  const reminderDetails = getOr('', 'getReminderDetails', data);
  const number_of_request = getOr(0, 'numSent', reminderDetails);
  const lastRequested = getOr('', 'lastSent', reminderDetails);

  const [isAddVendorsActive, setAddVendorsActive] = useState(false);

  const [requestAnotherVendor] = useMutation(REQUEST_ANOTHER_VENDOR);

  if (!customerData) {
    return <LoadingSpinner />
  }

  const handleRequestAnotherVendor = () => {
    requestAnotherVendor({ variables: { customerId: customerData.id } })
      .then(() => {
        showToast({
          title: 'Additonal Vendor Request Sent',
          description: 'Additonal Vendor Reference Request have been successfully Sent',
          type: toast.TYPE.SUCCESS,
        });
      });
  };

  const renderButtons = (
    <>
      {canSeeEditControl ? (
        <FlexColumn align='flex-end' gap='0.25rem'>
          <StyledButtonsContainer>
            <Button
              secondary
              small
              LeftIcon={EmailIcon}
              onClick={handleRequestAnotherVendor}
            >
              Request Another Vendor
            </Button>
          </StyledButtonsContainer>
          { number_of_request ? (
            <Caption>Requested {number_of_request} times, latest {formatDateToDateTimeString(lastRequested)}</Caption>
          ) : false }
          <StyledButtonsContainer>
            <Button
              secondary
              small
              onClick={() => setAddVendorsActive(true)}
            >
              Manually Add Reference
            </Button>
          </StyledButtonsContainer>
        </FlexColumn>
      )
        :
        false}
    </>
  )

  return (
    <StyledTabPanelContainer>
      <SectionContainer title='Vendor references'>
        <ReferencesList customerData={customerData} refetch={refetchCustomerData} canSeeEditControl={canSeeEditControl} />
        {isAddVendorsActive ? (
          <StyledAddVendorsForm>
            <AddVendor setAddVendorsActive={setAddVendorsActive} customerData={customerData} refetch={refetchCustomerData}/>
          </StyledAddVendorsForm>
        ) : false }
      </SectionContainer>
      {renderButtons}
    </StyledTabPanelContainer>
  )
};
