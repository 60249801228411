import React, { useContext } from 'react';
import { Auth0UserProfile } from 'auth0-js';

import { noop } from 'lodash/fp';
import { IOnboardCreditApplicantVariables } from '@/views/onboard/CreditApplicantOnboard/steps/CompanyContactsStep/types';
import { IOnboardValues } from '@/views/onboard/CreditApplicantOnboard/types';
import { FingerprintJSPro } from '@fingerprintjs/fingerprintjs-pro-spa';
import { IOnboardCreditProviderVariablesOnboardData } from '@/views/onboard/CreditProviderOnboard/steps/CompanyContactsStep/types';

export interface ICAOnboardOptions {
  onboardVariables: IOnboardCreditApplicantVariables,
  onboardValues: IOnboardValues,
  visitorData: FingerprintJSPro.ExtendedGetResult | null,
  referralCode: string | null,
  providerCompanyId: string,
}

export interface ICPOnboardOptions {
  onboardData: IOnboardCreditProviderVariablesOnboardData,
  redirectTo: string,
}

interface IAuth0ContextValue {
    login: (email: string, password: string) => Promise<any> | void,
    signup: (name: string, email: string, password: string) => Promise<any>,
    user: Auth0UserProfile | null,
    token: string | null,
    logout: () => void,
    loading: boolean,
    loginWithCompany: (email: string, password: string) => void,
    signUpCreditProvider: (
      email: string, password: string, onboardOptions: ICPOnboardOptions
    ) => Promise<unknown>,
    signUpOrLoginCreditApplicant: (
      name: string, email: string, password: string, onboardOptions: ICAOnboardOptions
    ) => Promise<unknown>,
    clearSession: () => void,
    companyId: string | null,
  }

const defaultValue = {
  login: noop,
  signup: () => Promise.resolve(),
  user: null,
  token: null,
  logout: noop,
  loading: false,
  loginWithCompany: noop,
  signUpCreditProvider: () => Promise.resolve(),
  signUpOrLoginCreditApplicant: () => Promise.resolve(),
  clearSession: noop,
  companyId: null,
}

export const Auth0Context = React.createContext<IAuth0ContextValue>(defaultValue);
export const useAuth0 = () => useContext(Auth0Context);