import React, { Dispatch, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FingerprintJSPro } from '@fingerprintjs/fingerprintjs-pro-spa';

import { useMutation } from '@apollo/react-hooks';
import { UPSERT_VENDOR_REFERENCES } from '@/graphql/mutations/upsertVendorReferences';

import { Formik } from 'formik';

import { noop } from 'lodash/fp';
import { handleSubmitWrapper } from '@/utils/handleSubmitWrapper';

import { VisitorContext } from '@/providers/VisitorContext';

import { showToast, toast } from '@/containers/StyledToastContainer/toast';

import { LoadingSpinner } from '@/components/LoadingSpinner';

import { OnboardValuesContext } from '../../context';
import { vendorsStepValidation } from './validation';
import { VendorsStepForm } from './form';
import { IOnboardValuesAction } from '../../types';
import { UPDATE_VENDORS } from '../../reducer';
import { IVendorsValues } from './types';
import { mapOnboardValuesToUpsertVendorReferencesVariables } from './functions';

interface IProps {
  dispatch: Dispatch<IOnboardValuesAction>,
  providerCompanyId: string,
  totalReferencesNumber: number,
  requiredReferencesNumber: number
}

export const VendorsStep = ({ dispatch, providerCompanyId }: IProps) => {
  const visitorData: FingerprintJSPro.ExtendedGetResult | null = useContext(VisitorContext);
  const history = useHistory();
  const { search } = useLocation();

  const onboardValues = useContext(OnboardValuesContext);
  const firstUpdate = useRef(true);

  const [upsertVendorReferences] = useMutation(UPSERT_VENDOR_REFERENCES);

  const submitStep = () => {

    if (!onboardValues) {
      return noop;
    }

    const upsertVendorReferencesVariables = mapOnboardValuesToUpsertVendorReferencesVariables(
      onboardValues.vendors.requiredVendors,
      onboardValues.vendors.optionalVendors
    );

    return upsertVendorReferences({
      variables: {
        customerId: onboardValues.customerId,
        vendorReferences: upsertVendorReferencesVariables,
        blockSend: true,
        deleteExisting: true,
        forCompanyId: providerCompanyId,
        visitorData
      }
    })
      .then(() => {
        history.push({ pathname: `${onboardValues.basePath}/others`, search });
      })
      .catch(() => {
        showToast({
          title: 'Error',
          description: 'Something went wrong',
          type: toast.TYPE.ERROR,
        });
      });
  }

  useEffect(() => {
    // To skip function call after initial render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    submitStep();
  }, [onboardValues]);

  const dispatchValues = (values: IVendorsValues) => {
    dispatch({ type: UPDATE_VENDORS, payload: values });
  }

  if (!onboardValues) {
    return <LoadingSpinner />
  }

  return (
    <Formik
      initialValues={onboardValues.vendors}
      validationSchema={
        vendorsStepValidation(
          onboardValues.basicInfo.email.split('@')[1],
          onboardValues.companyProfile.website,
        )
      }
      onSubmit={(values) => handleSubmitWrapper(values, dispatchValues)}
    >
      <VendorsStepForm />
    </Formik>
  )
};
